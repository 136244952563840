import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PageBanner from "../components/banners/pageBanner"
//import ProjectFilter from "../components/links/projectFilter"
import ProjectBlocks from "../components/archives/projectBlocks"

const Projects = props => {
	const {
		data: {
			wpgraphql: { projects }
		},
	} = props;

	return (
		<Layout>
			<div>
				<PageBanner 
					title={`Portfolio`}
				/>
				<section className="preview light">
					{/*<div className="constrained">
						<nav className="preview__nav">
							<ProjectFilter 
								taxonomies={industries}
								title='Industry'
							/>
						</nav>
					</div>*/}
					<ProjectBlocks projects={projects} />
				</section>
			</div>
		</Layout>
	)
}

export default Projects;

export const pageQuery = graphql`
	query GET_PROJECTS($ids: [ID]) {
		wpgraphql {
			projects(where: {status: PUBLISH, in: $ids}, first: 999) {
				nodes {
					id
					title(format: RENDERED)
					uri
					featuredImage {
						node {
							sourceUrl(size: MEDIUM_LARGE)
						}
					}
					acfWork {
					  	previewBlockTitle
					}
				}
			}
			industries(where: {hideEmpty: true}, first: 999) {
				nodes {
					id
					name
					uri
					slug
				}
			}
		}
	}
`
