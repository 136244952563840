import React from "react"
import { Link } from "gatsby"

const ProjectBlocks = ({ projects }) => {
	return (
		<div className="preview__main preview__main--archive">
			{projects.nodes.map(project => (
				<div key={project.id} className="preview__block filter-industry-all">
					<div className="preview__inner">
						<div className="preview__helper"></div>
						<div className="preview__image">
							<img src={project.featuredImage.node.sourceUrl} alt={project.title + ' Screenshot'} />
						</div>
						<Link to={`/${project.uri}`} className="preview__content">
							<h3 dangerouslySetInnerHTML={{ __html: ( project.acfWork.previewBlockTitle !== '' ) ? project.acfWork.previewBlockTitle : project.title }}></h3>
						</Link>
					</div>
				</div>
			))}
		</div>
	)
}

export default ProjectBlocks;